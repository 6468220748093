.navbar-brand{
    font-size: 25px;
}

#backgroundSectionImg{
    background-image: url(../../img/bg_1.jpg);
    background-position: center;
}

.homeServiceIcons{
    color: #fc983c;
    font-size: 40px !important;
}

.block-6 .media-body p{
    font-size: 18px;
}

.services .media-body h3{
    font-weight: bold;
}

.hero-wrap.hero-wrap-2 {
    height: 300px!important;
}

.hero-wrap.hero-wrap-2 .overlay {
    width: 100%;
    opacity: 0.7;
    height: 300px;
}

.hero-wrap.hero-wrap-2 .slider-text{
    height: 300px!important;
}

.slider-text .icon-wrap .icon {
    width: unset; 
    height: unset;
    background: #fc983c;
    border-radius: 8%;
    padding: 6px 8px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
}

svg.carDetailsIcons {
    font-size: 50px;
    color: #f6b71c;
    border: 1px solid #f6b71c5e;
    padding: 6px;
    border-radius: 25px;
}

.duwsXj img {
    max-height: unset !important;
}

.carDetPageBookNowBtn{
    border: 1px solid #fff;
    padding: 12px 12px;
    background: #f39100;
    color: #fff;
}